import React, {
    useContext,
    FC,
    useState,
    useRef,
    useLayoutEffect,
    MutableRefObject,
} from 'react';
import ImageComponent from "../utilities/ImageComponent";
import IframeVideo from "../utilities/VideoUtils/IframeVideo";
import {
    getFormattedEventData,
    getTimezoneAbbr,
    getVideoElementTypeFromItemType,
} from "../../utils/CleversiteUtilities";
import Html5Video from "../utilities/VideoUtils/Html5Video";
import moment from "moment";
import {capitalize, getPlatform, getSocialIcon} from "../../utils/StringUtilities";
import styles from "../cleverfeed/blocks/styles/ListItemGroup.module.scss";
import FlatIcon from "../utilities/FlatIcon";
import FontAwesome from "../utilities/FontAwesome";
import {observer} from "mobx-react";
import {modalStore, StoreContext} from "../../stores/StoreLoader";
import classNames from 'classnames';
import {getContentItemIcon} from "../blocks/TerracedGridTheme/FilesFoldersBlock/fileUtilities";
import ClickableLink from "../utilities/ClickableLink";
import SocialMarkdown from "../utilities/SocialMarkdown";
import {sourceTypesEnum} from "../../utils/SourceUtils";
import {
    IReactiveCourseWorkContentItem,
    IReactiveEventContentItem,
    IReactiveNewsContentItem, isCoursework,
} from "../../stores/SchoolFeedStore";
import {
    getCourseWorkIcons,
    handleContentItemClick,
} from "../blocks/SchoolBlocks/ContentItemBlock/ContentItemBlock";
import WatsonApi from "../../backends/WatsonApi";
import {SocialItemModalNavigationProps} from "../modals/SocialItemModal";

function ContentItemDate(props: {
    contentItem: ContentItem,
}) {
    const date = moment(props.contentItem.created_at).format('MMM D, YYYY');
    return <div className={styles.contentItemDate}>
        <div className={styles.date}>
            {date}
        </div>
    </div>
}

export function isEvent(item: EventContentItem | ContentItem): item is EventContentItem {
    return (item as EventContentItem).is_event === true;
}

export function isMedia(item: MediaContentItem | ContentItem): item is MediaContentItem {
    return (item as MediaContentItem).is_media === true;
}

export function isFile(item: FileContentItem | ContentItem): item is FileContentItem {
    return (item as FileContentItem).is_file === true;
}

export function isFolder(item: FileContentItem | ContentItem): item is FileContentItem {
    return (item as FolderContentItem).is_folder === true;
}

export function isNews(item: NewsContentItem | ContentItem): item is NewsContentItem {
    return (item as NewsContentItem).is_news === true;
}


export function Media (props) {
    let media: JSX.Element | null = <></>;
    if (isMedia(props.itemObj) && props.itemObj.json_data.videos && props.itemObj.json_data.videos.videos_list) {
        const type = getVideoElementTypeFromItemType(props.itemObj.type);
        media = type === "iframe" ?
            <IframeVideo embedLink={props.itemObj.json_data.videos.videos_list[0].raw_url}/> :
            <Html5Video embedLink={props.itemObj.json_data.videos.videos_list[0].raw_url}/>;
    } else if (isEvent(props.itemObj) && props.itemObj.json_data.event) {
        const start = moment(props.itemObj.json_data.event.start);
        const end = moment(props.itemObj.json_data.event.end);

        const startMonth = start.format("MMM");
        const startDay = start.format("D");

        if (props.itemObj.json_data.event.multi_day) {
            const endMonth = end.format("MMM");
            const endDay = end.format("D");

            if (startMonth === endMonth) {
                media = <div className={props.style}>
                    <span>{startMonth}</span>
                    <span>{startDay} - {endDay}</span>
                </div>
            } else {
                media = <div className={props.style}>
                    <span>{startMonth} {startDay} -</span>
                    <span>{endMonth} {endDay}</span>
                </div>
            }
        } else {
            media = <div className={props.style}>
                <span>{startMonth}</span>
                <span>{startDay}</span>
            </div>
        }
    }
    else if (isCoursework(props.itemObj)) {
        media = getCourseWorkIcons(props.itemObj);
    }
    else if (props.itemObj.image) {
        const imageIsSourceImage = props.itemObj.image === props.itemObj.source?.account_image || props.itemObj.image === props.itemObj.source?.account_image?.replace("_normal", "");
        media = <ImageComponent data-smallwidth={imageIsSourceImage}
                                src={props.itemObj.image}
                                alt={props.itemObj.altText}
                                onClick={props.onClick}/>
    }

    return media;
}

export function ItemDetails (props) {
    let itemDetails;
    let formatted;

    if (props.itemObj.is_event && (props.itemObj as IReactiveEventContentItem).json_data.event) {
        formatted = getFormattedEventData(props.itemObj, undefined, "h:mma");
    }
    if (isEvent(props.itemObj)) {
        itemDetails = <div className={props.style}>
            {formatted?.timeStr && <div>
                <FlatIcon name={'flaticon-clock'}/>&nbsp;<span>{formatted.timeStr}</span>&nbsp;<span>{formatted.timeStr !== "All Day" && getTimezoneAbbr()}</span>
            </div>}
            {props.itemObj.json_data.event.location && <div role={"button"} onClick={() => modalStore.addModal({
                type: "events",
                itemObj: props.itemObj,
                id: props.itemObj.id,
            })}>
                <FlatIcon name={'flaticon-map'}/> {props.itemObj.json_data.event.location}
            </div>}
        </div>
    } else if (isFile(props.itemObj)) {
        const iconUrl = getContentItemIcon(props.itemObj);
        itemDetails = <div className={props.style}>
            <ClickableLink href={props.itemObj.json_data.file.view_link}>
                <img src={iconUrl} alt={"File Icon"} />
                Click to View File
            </ClickableLink>
        </div>
    }

    return itemDetails;
}

const Item = observer((props: {
    itemObj: IReactiveNewsContentItem | IReactiveEventContentItem | IReactiveCourseWorkContentItem,
    allowCuration: boolean,
    deleteItem: (item: IReactiveEventContentItem | IReactiveNewsContentItem) => void,
    unpublishItem: (item: IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem) => void,
    handleClick: () => void,
}) => {
    const {userStore, sidebarStore} = useContext(StoreContext);
    const itemRef: MutableRefObject<any> = useRef(null);
    const [shortened, setShortened] = useState(false);

    const handleKeydown = (evt) => {
        if (evt.code === "Enter") {
            const element: HTMLElement = document.activeElement as HTMLElement;
            element.click();
        }
    }

    useLayoutEffect(() => {
        setShortened(itemRef?.current?.clientHeight > 350);
    }, []);

    const itemClassName = classNames({
        [styles.item]: true,
        [styles.itemHidden]: !props.itemObj.published,
        [styles.itemShortened]: shortened,
    })

    let sourceIconLink;
    const platform = getPlatform(props.itemObj.type);
    if (props.itemObj.third_party_url && ![sourceTypesEnum.ICAL, sourceTypesEnum.RSS].includes(platform)) {
        const iconClassName = classNames({
            [styles[`sourceIcon${capitalize(platform)}`]]: true,
            [styles.sourceIcon]: true,
        })
        sourceIconLink = <ClickableLink className={iconClassName} href={props.itemObj.third_party_url} title={props.itemObj.source.account_title}>
            <FontAwesome name={getSocialIcon(platform, true)} />
        </ClickableLink>
    }

    return (
        <div
        data-item={true}
        className={`${itemClassName} scroll-item-news`}
        ref={itemRef}
        onClick={e => {
          e.stopPropagation();
          props.handleClick();
        }}
      >
            {userStore.isEditor && (
                <div className={styles.curationContainer}>
                    <button
                        aria-label={'Edit Content Item'}
                        onClick={async e => {
                            e.stopPropagation();
                            sidebarStore.setSidebar({
                              view: "SchoolFeedPost",
                              contentItem: props.itemObj,
                              handleDelete: async contentItem => {
                                await props.deleteItem(contentItem);
                              },
                            });
                        }}
            >
                        <FontAwesome ariaHidden prefix={"fas"} name={"fa-pencil-alt"} />
                    </button>
                </div>
        )}

            <div className={styles.header}>
                {props.itemObj.title && (
                    <div className={styles.title}>
                        {isNews(props.itemObj) && <div className={styles.dateContainer}>
                            <ContentItemDate contentItem={props.itemObj} />
                        </div>}
                        {props.itemObj.meritchat_enabled &&
                            <div className={styles.meritChatCallout}>
                                <FontAwesome ariaHidden prefix={"far"} name={"fa-comments"} />
                            </div>
                            }
                        <SocialMarkdown platform={platform} text={props.itemObj.title}/>
                    </div>
          ) }

                {isCoursework(props.itemObj) &&
            props.itemObj.json_data?.coursework?.dueDate && (
                <div className={styles.date} style={{ fontWeight: "bold" }}>
                    Due Date:{" "}
                    {moment(props.itemObj.json_data?.coursework?.dueDate).format(
                  "MMM D, YYYY"
                )}
                </div>
            )}
                {isEvent(props.itemObj) && (
                    <ItemDetails
              itemObj={props.itemObj}
              style={styles.dateLocationContainer}
            />
          )}
                {props.itemObj.description && (
                    <div
              className={styles.description}
              tabIndex={shortened ? 0 : -1}
              onKeyDown={shortened ? handleKeydown : undefined}
            >
                        {sourceIconLink}
                        <SocialMarkdown
                text={props.itemObj.description}
                platform={platform}
              />
                    </div>
          )}
                {isFile(props.itemObj) && (
                    <ItemDetails
              itemObj={props.itemObj}
              style={styles.dateLocationContainer}
            />
          )}

            </div>
        </div>
    );
});

const ListItemGroup: FC<{
    items: Array<IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem>,
    allowCuration: boolean,
    showMedia: boolean,
    firstItemIndex: number,
    handleDelete: (c: ContentItem) => void,
} & Omit<SocialItemModalNavigationProps, "initialItemIndex">> = observer(props => {

    const firstItem = props.items[0];

    async function deleteItem(item: IReactiveNewsContentItem | IReactiveEventContentItem) {
        const client = await WatsonApi();
        await client.apis.organizations.organizations_content_items_delete({
            id: item.id,
            organization_pk: item.organization_id,
        });
        props.handleDelete(item);
    }

    async function unpublishItem(item: IReactiveNewsContentItem | IReactiveEventContentItem | IReactiveCourseWorkContentItem) {
        const client = await WatsonApi();
        const result = await client.apis.organizations.organizations_content_items_partial_update({
            id: item.id,
            organization_pk: item.organization_id,
            data: {"user_published": !item.published},
        });
        item.update(result.obj);
    }

    return <div className={styles.groupContainer}>
        {props.showMedia && <Media itemObj={firstItem} style={styles.dateMedia} onClick={(e) => {
            e.stopPropagation();
            handleContentItemClick(firstItem, {
                getItemAtIndex: props.getItemAtIndex,
                totalItemCount: props.totalItemCount,
                initialItemIndex: props.firstItemIndex,
            }, deleteItem) }
        }/>}
        <div className={styles.contentContainer}>
            {props.items.map((item, idx) => {
                return <Item
                    allowCuration={props.allowCuration}
                    key={item.id}
                    itemObj={item}
                    deleteItem={deleteItem}
                    unpublishItem={unpublishItem}
                    handleClick={() => handleContentItemClick(item, {
                        getItemAtIndex: props.getItemAtIndex,
                        totalItemCount: props.totalItemCount,
                        initialItemIndex: props.firstItemIndex + idx,
                    }, deleteItem)}
                /> })}
        </div>
    </div>
})

export default ListItemGroup
